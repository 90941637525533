import _core from "./core.js";
var exports = {};
var Promise = _core;
exports = Promise;

Promise.enableSynchronous = function () {
  Promise.prototype.isPending = function () {
    return this.getState() == 0;
  };

  Promise.prototype.isFulfilled = function () {
    return this.getState() == 1;
  };

  Promise.prototype.isRejected = function () {
    return this.getState() == 2;
  };

  Promise.prototype.getValue = function () {
    if (this._V === 3) {
      return this._W.getValue();
    }

    if (!this.isFulfilled()) {
      throw new Error("Cannot get a value of an unfulfilled promise.");
    }

    return this._W;
  };

  Promise.prototype.getReason = function () {
    if (this._V === 3) {
      return this._W.getReason();
    }

    if (!this.isRejected()) {
      throw new Error("Cannot get a rejection reason of a non-rejected promise.");
    }

    return this._W;
  };

  Promise.prototype.getState = function () {
    if (this._V === 3) {
      return this._W.getState();
    }

    if (this._V === -1 || this._V === -2) {
      return 0;
    }

    return this._V;
  };
};

Promise.disableSynchronous = function () {
  Promise.prototype.isPending = undefined;
  Promise.prototype.isFulfilled = undefined;
  Promise.prototype.isRejected = undefined;
  Promise.prototype.getValue = undefined;
  Promise.prototype.getReason = undefined;
  Promise.prototype.getState = undefined;
};

export default exports;